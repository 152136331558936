.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*,:before,:after{
  box-sizing:border-box;
  border-width:0;
  border-style:solid;
  border-color:currentColor
}
:before,:after{
  --tw-content: ""
}
html{
  line-height:1.5;
  -webkit-text-size-adjust:100%;
  -moz-tab-size:4;
  -o-tab-size:4;
  tab-size:4;
  font-family:ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji"
}
body{
  margin:0;
  line-height:inherit
}
hr{
  height:0;
  color:inherit;
  border-top-width:1px
}
abbr[title]{
  -webkit-text-decoration:underline dotted;
  text-decoration:underline dotted
}
h1,h2,h3,h4,h5,h6{
  font-size:inherit;
  font-weight:inherit
}
a{
  color:inherit;
  text-decoration:inherit
}
b,strong{
  font-weight:bolder
}
code,kbd,samp,pre{
  font-family:ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
  font-size:1em
}
small{
  font-size:80%
}
sub,sup{
  font-size:75%;
  line-height:0;
  position:relative;
  vertical-align:baseline
}
sub{
  bottom:-.25em
}
sup{
  top:-.5em
}
table{
  text-indent:0;
  border-color:inherit;
  border-collapse:collapse
}
button,input,optgroup,select,textarea{
  font-family:inherit;
  font-size:100%;
  line-height:inherit;
  color:inherit;
  margin:0;
  padding:0
}
button,select{
  text-transform:none
}
button,[type=button],[type=reset],[type=submit]{
  -webkit-appearance:button;
  background-color:transparent;
  background-image:none
}
:-moz-focusring{
  outline:auto
}
:-moz-ui-invalid{
  box-shadow:none
}
progress{
  vertical-align:baseline
}
::-webkit-inner-spin-button,::-webkit-outer-spin-button{
  height:auto
}
[type=search]{
  -webkit-appearance:textfield;
  outline-offset:-2px
}
::-webkit-search-decoration{
  -webkit-appearance:none
}
::-webkit-file-upload-button{
  -webkit-appearance:button;
  font:inherit
}
summary{
  display:list-item
}
blockquote,dl,dd,h1,h2,h3,h4,h5,h6,hr,figure,p,pre{
  margin:0
}
fieldset{
  margin:0;
  padding:0
}
legend{
  padding:0
}
ol,ul,menu{
  list-style:none;
  margin:0;
  padding:0
}
textarea{
  resize:vertical
}
input::-moz-placeholder,textarea::-moz-placeholder{
  opacity:1;
  color:#9ca3af
}
input:-ms-input-placeholder,textarea:-ms-input-placeholder{
  opacity:1;
  color:#9ca3af
}
input::placeholder,textarea::placeholder{
  opacity:1;
  color:#9ca3af
}
button,[role=button]{
  cursor:pointer
}
:disabled{
  cursor:default
}
img,svg,video,canvas,audio,iframe,embed,object{
  display:block;
  vertical-align:middle
}
img,video{
  max-width:100%;
  height:auto
}
[hidden]{
  display:none
}
:root,[data-theme]{
  background-color:hsla(var(--b1) / var(--tw-bg-opacity, 1));
  color:hsla(var(--bc) / var(--tw-text-opacity, 1))
}
html{
  -webkit-tap-highlight-color:transparent
}
:root{
  --p: 259 94% 51%;
  --pf: 259 94% 41%;
  --sf: 314 100% 38%;
  --af: 174 60% 41%;
  --nf: 219 14% 22%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --pc: 0 0% 100%;
  --s: 314 100% 47%;
  --sc: 0 0% 100%;
  --a: 174 60% 51%;
  --ac: 175 44% 15%;
  --n: 219 14% 28%;
  --nc: 0 0% 100%;
  --b1: 0 0% 100%;
  --b2: 0 0% 95%;
  --b3: 180 2% 90%;
  --bc: 215 28% 17%
}
*,:before,:after{
  --tw-border-opacity: 1;
  border-color:rgb(229 231 235 / var(--tw-border-opacity));
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000
}
.container{
  width:100%
}
@media (min-width: 640px){
  .container{
      max-width:640px
  }
}
@media (min-width: 768px){
  .container{
      max-width:768px
  }
}
@media (min-width: 1024px){
  .container{
      max-width:1024px
  }
}
@media (min-width: 1280px){
  .container{
      max-width:1280px
  }
}
@media (min-width: 1536px){
  .container{
      max-width:1536px
  }
}
@-webkit-keyframes spin{
  0%{
      transform:rotate(0)
  }
  to{
      transform:rotate(360deg)
  }
}
@keyframes spin{
  0%{
      transform:rotate(0)
  }
  to{
      transform:rotate(360deg)
  }
}
.footer{
  display:grid;
  width:100%;
  grid-auto-flow:row;
  place-items:start;
  row-gap:2.5rem;
  -moz-column-gap:1rem;
  column-gap:1rem;
  font-size:.875rem;
  line-height:1.25rem
}
.footer>*{
  display:grid;
  place-items:start;
  gap:.5rem
}
.footer-center{
  place-items:center;
  text-align:center
}
.footer-center>*{
  place-items:center
}
@media (min-width: 48rem){
  .footer{
      grid-auto-flow:column
  }
  .footer-center{
      grid-auto-flow:row dense
  }
}
.link{
  cursor:pointer;
  -webkit-text-decoration-line:underline;
  text-decoration-line:underline
}
.link-hover{
  -webkit-text-decoration-line:none;
  text-decoration-line:none
}
.link-hover:hover{
  -webkit-text-decoration-line:underline;
  text-decoration-line:underline
}
.select{
  display:inline-flex;
  flex-shrink:0;
  cursor:pointer;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
  height:3rem;
  padding-left:1rem;
  padding-right:2.5rem;
  font-size:.875rem;
  line-height:2;
  min-height:3rem;
  border-width:1px;
  border-color:hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color:hsl(var(--b1) / var(--tw-bg-opacity));
  font-weight:600;
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-duration:.2s;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  border-radius:var(--rounded-btn, .5rem);
  background-image:linear-gradient(45deg,transparent 50%,currentColor 50%),linear-gradient(135deg,currentColor 50%,transparent 50%);
  background-position:calc(100% - 20px) calc(1px + 50%),calc(100% - 16px) calc(1px + 50%);
  background-size:4px 4px,4px 4px;
  background-repeat:no-repeat
}
.select-disabled,.select[disabled]{
  pointer-events:none;
  cursor:not-allowed;
  --tw-border-opacity: 1;
  border-color:hsl(var(--b2, var(--b1)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  --tw-text-opacity: .2
}
.steps{
  display:inline-grid;
  grid-auto-flow:column;
  overflow:hidden;
  overflow-x:auto;
  counter-reset:step;
  grid-auto-columns:1fr
}
.steps .step{
  display:grid;
  grid-template-columns:auto;
  grid-template-rows:40px 1fr;
  place-items:center;
  text-align:center;
  min-width:4rem
}
@-webkit-keyframes button-pop{
  0%{
      transform:scale(var(--btn-focus-scale, .95))
  }
  40%{
      transform:scale(1.02)
  }
  to{
      transform:scale(1)
  }
}
@keyframes button-pop{
  0%{
      transform:scale(var(--btn-focus-scale, .95))
  }
  40%{
      transform:scale(1.02)
  }
  to{
      transform:scale(1)
  }
}
@-webkit-keyframes checkmark{
  0%{
      background-position-y:5px
  }
  50%{
      background-position-y:-2px
  }
  to{
      background-position-y:0
  }
}
@keyframes checkmark{
  0%{
      background-position-y:5px
  }
  50%{
      background-position-y:-2px
  }
  to{
      background-position-y:0
  }
}
.link:focus{
  outline:2px solid transparent;
  outline-offset:2px
}
.link:focus-visible{
  outline:2px solid currentColor;
  outline-offset:2px
}
.mockup-phone .display{
  overflow:hidden;
  border-radius:40px;
  margin-top:-25px
}
@-webkit-keyframes progress-loading{
  50%{
      left:107%
  }
}
@keyframes progress-loading{
  50%{
      left:107%
  }
}
@-webkit-keyframes radiomark{
  0%{
      box-shadow:0 0 0 12px hsl(var(--b1)) inset,0 0 0 12px hsl(var(--b1)) inset
  }
  50%{
      box-shadow:0 0 0 3px hsl(var(--b1)) inset,0 0 0 3px hsl(var(--b1)) inset
  }
  to{
      box-shadow:0 0 0 4px hsl(var(--b1)) inset,0 0 0 4px hsl(var(--b1)) inset
  }
}
@keyframes radiomark{
  0%{
      box-shadow:0 0 0 12px hsl(var(--b1)) inset,0 0 0 12px hsl(var(--b1)) inset
  }
  50%{
      box-shadow:0 0 0 3px hsl(var(--b1)) inset,0 0 0 3px hsl(var(--b1)) inset
  }
  to{
      box-shadow:0 0 0 4px hsl(var(--b1)) inset,0 0 0 4px hsl(var(--b1)) inset
  }
}
@-webkit-keyframes rating-pop{
  0%{
      transform:translateY(-.125em)
  }
  40%{
      transform:translateY(-.125em)
  }
  to{
      transform:translateY(0)
  }
}
@keyframes rating-pop{
  0%{
      transform:translateY(-.125em)
  }
  40%{
      transform:translateY(-.125em)
  }
  to{
      transform:translateY(0)
  }
}
.select:focus{
  outline:2px solid hsla(var(--bc) / .2);
  outline-offset:2px
}
.select-disabled::-moz-placeholder,.select[disabled]::-moz-placeholder{
  color:hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2
}
.select-disabled:-ms-input-placeholder,.select[disabled]:-ms-input-placeholder{
  color:hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2
}
.select-disabled::placeholder,.select[disabled]::placeholder{
  color:hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2
}
.select-multiple,.select[multiple],.select[size].select:not([size="1"]){
  background-image:none;
  padding-right:1rem
}
.steps .step:before{
  top:0px;
  grid-column-start:1;
  grid-row-start:1;
  height:.5rem;
  width:100%;
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color:hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:hsl(var(--bc) / var(--tw-text-opacity));
  content:"";
  margin-left:-100%
}
.steps .step:after{
  content:counter(step);
  counter-increment:step;
  z-index:1;
  position:relative;
  grid-column-start:1;
  grid-row-start:1;
  display:grid;
  height:2rem;
  width:2rem;
  place-items:center;
  place-self:center;
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:hsl(var(--bc) / var(--tw-text-opacity))
}
.steps .step:first-child:before{
  content:none
}
.steps .step[data-content]:after{
  content:attr(data-content)
}
.steps .step-neutral+.step-neutral:before,.steps .step-neutral:after{
  --tw-bg-opacity: 1;
  background-color:hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:hsl(var(--nc) / var(--tw-text-opacity))
}
.steps .step-primary+.step-primary:before,.steps .step-primary:after{
  --tw-bg-opacity: 1;
  background-color:hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:hsl(var(--pc) / var(--tw-text-opacity))
}
.steps .step-secondary+.step-secondary:before,.steps .step-secondary:after{
  --tw-bg-opacity: 1;
  background-color:hsl(var(--s) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:hsl(var(--sc) / var(--tw-text-opacity))
}
.steps .step-accent+.step-accent:before,.steps .step-accent:after{
  --tw-bg-opacity: 1;
  background-color:hsl(var(--a) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:hsl(var(--ac) / var(--tw-text-opacity))
}
.steps .step-info+.step-info:before{
  --tw-bg-opacity: 1;
  background-color:hsl(var(--in) / var(--tw-bg-opacity))
}
.steps .step-info:after{
  --tw-bg-opacity: 1;
  background-color:hsl(var(--in) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:hsl(var(--inc, var(--nc)) / var(--tw-text-opacity))
}
.steps .step-success+.step-success:before{
  --tw-bg-opacity: 1;
  background-color:hsl(var(--su) / var(--tw-bg-opacity))
}
.steps .step-success:after{
  --tw-bg-opacity: 1;
  background-color:hsl(var(--su) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:hsl(var(--suc, var(--nc)) / var(--tw-text-opacity))
}
.steps .step-warning+.step-warning:before{
  --tw-bg-opacity: 1;
  background-color:hsl(var(--wa) / var(--tw-bg-opacity))
}
.steps .step-warning:after{
  --tw-bg-opacity: 1;
  background-color:hsl(var(--wa) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:hsl(var(--wac, var(--nc)) / var(--tw-text-opacity))
}
.steps .step-error+.step-error:before{
  --tw-bg-opacity: 1;
  background-color:hsl(var(--er) / var(--tw-bg-opacity))
}
.steps .step-error:after{
  --tw-bg-opacity: 1;
  background-color:hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:hsl(var(--erc, var(--nc)) / var(--tw-text-opacity))
}
.steps-horizontal .step{
  display:grid;
  grid-template-columns:repeat(1,minmax(0,1fr));
  grid-template-rows:repeat(2,minmax(0,1fr));
  place-items:center;
  text-align:center
}
.steps-vertical .step{
  display:grid;
  grid-template-columns:repeat(2,minmax(0,1fr));
  grid-template-rows:repeat(1,minmax(0,1fr))
}
.steps-horizontal .step{
  grid-template-rows:40px 1fr;
  grid-template-columns:auto;
  min-width:4rem
}
.steps-horizontal .step:before{
  height:.5rem;
  width:100%;
  --tw-translate-y: 0px;
  --tw-translate-x: 0px;
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  content:"";
  margin-left:-100%
}
.steps-vertical .step{
  gap:.5rem;
  grid-template-columns:40px 1fr;
  grid-template-rows:auto;
  min-height:4rem;
  justify-items:start
}
.steps-vertical .step:before{
  height:100%;
  width:.5rem;
  --tw-translate-y: -50%;
  --tw-translate-x: -50%;
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  margin-left:50%
}
.fixed{
  position:fixed
}
.bottom-0{
  bottom:0px
}
.mx-auto{
  margin-left:auto;
  margin-right:auto
}
.my-2{
  margin-top:.5rem;
  margin-bottom:.5rem
}
.my-4{
  margin-top:1rem;
  margin-bottom:1rem
}
.mx-4{
  margin-left:1rem;
  margin-right:1rem
}
.my-1{
  margin-top:.25rem;
  margin-bottom:.25rem
}
.mt-8{
  margin-top:2rem
}
.mb-8{
  margin-bottom:2rem
}
.mt-0{
  margin-top:0
}
.mb-4{
  margin-bottom:1rem
}
.mt-4{
  margin-top:1rem
}
.mt-2{
  margin-top:.5rem
}
.mb-2{
  margin-bottom:.5rem
}
.mb-6{
  margin-bottom:1.5rem
}
.block{
  display:block
}
.flex{
  display:flex
}
.grid{
  display:grid
}
.hidden{
  display:none
}
.w-full{
  width:100%
}
.w-3\/4{
  width:75%
}
.w-max{
  width:-webkit-max-content;
  width:-moz-max-content;
  width:max-content
}
.max-w-3xl{
  max-width:48rem
}
.max-w-4xl{
  max-width:56rem
}
.max-w-5xl{
  max-width:64rem
}
.list-disc{
  list-style-type:disc
}
.grid-flow-col{
  grid-auto-flow:column
}
.grid-cols-1{
  grid-template-columns:repeat(1,minmax(0,1fr))
}
.items-center{
  align-items:center
}
.justify-center{
  justify-content:center
}
.justify-between{
  justify-content:space-between
}
.gap-4{
  gap:1rem
}
.gap-6{
  gap:1.5rem
}
.break-normal{
  overflow-wrap:normal;
  word-break:normal
}
.rounded-2xl{
  border-radius:1rem
}
.rounded-md{
  border-radius:.375rem
}
.rounded-lg{
  border-radius:.5rem
}
.border-0{
  border-width:0px
}
.border-2{
  border-width:2px
}
.border-b-2{
  border-bottom-width:2px
}
.border-t-2{
  border-top-width:2px
}
.border-dashed{
  border-style:dashed
}
.border-blue-500{
  --tw-border-opacity: 1;
  border-color:rgb(59 130 246 / var(--tw-border-opacity))
}
.border-red-500{
  --tw-border-opacity: 1;
  border-color:rgb(239 68 68 / var(--tw-border-opacity))
}
.border-orange-900{
  --tw-border-opacity: 1;
  border-color:rgb(124 45 18 / var(--tw-border-opacity))
}
.bg-red-400{
  --tw-bg-opacity: 1;
  background-color:rgb(248 113 113 / var(--tw-bg-opacity))
}
.bg-red-500{
  --tw-bg-opacity: 1;
  background-color:rgb(239 68 68 / var(--tw-bg-opacity))
}
.bg-red-600{
  --tw-bg-opacity: 1;
  background-color:rgb(220 38 38 / var(--tw-bg-opacity))
}
.bg-red-700{
  --tw-bg-opacity: 1;
  background-color:rgb(185 28 28 / var(--tw-bg-opacity))
}
.bg-orange-400{
  --tw-bg-opacity: 1;
  background-color:rgb(251 146 60 / var(--tw-bg-opacity))
}
.bg-orange-500{
  --tw-bg-opacity: 1;
  background-color:rgb(249 115 22 / var(--tw-bg-opacity))
}
.bg-orange-600{
  --tw-bg-opacity: 1;
  background-color:rgb(234 88 12 / var(--tw-bg-opacity))
}
.bg-orange-700{
  --tw-bg-opacity: 1;
  background-color:rgb(194 65 12 / var(--tw-bg-opacity))
}
.bg-green-400{
  --tw-bg-opacity: 1;
  background-color:rgb(74 222 128 / var(--tw-bg-opacity))
}
.bg-green-500{
  --tw-bg-opacity: 1;
  background-color:rgb(34 197 94 / var(--tw-bg-opacity))
}
.bg-green-600{
  --tw-bg-opacity: 1;
  background-color:rgb(22 163 74 / var(--tw-bg-opacity))
}
.bg-green-700{
  --tw-bg-opacity: 1;
  background-color:rgb(21 128 61 / var(--tw-bg-opacity))
}
.bg-blue-400{
  --tw-bg-opacity: 1;
  background-color:rgb(96 165 250 / var(--tw-bg-opacity))
}
.bg-blue-500{
  --tw-bg-opacity: 1;
  background-color:rgb(59 130 246 / var(--tw-bg-opacity))
}
.bg-blue-600{
  --tw-bg-opacity: 1;
  background-color:rgb(37 99 235 / var(--tw-bg-opacity))
}
.bg-blue-700{
  --tw-bg-opacity: 1;
  background-color:rgb(29 78 216 / var(--tw-bg-opacity))
}
.bg-gray-300{
  --tw-bg-opacity: 1;
  background-color:rgb(209 213 219 / var(--tw-bg-opacity))
}
.bg-yellow-500{
  --tw-bg-opacity: 1;
  background-color:rgb(234 179 8 / var(--tw-bg-opacity))
}
.bg-yellow-100{
  --tw-bg-opacity: 1;
  background-color:rgb(254 249 195 / var(--tw-bg-opacity))
}
.bg-yellow-300{
  --tw-bg-opacity: 1;
  background-color:rgb(253 224 71 / var(--tw-bg-opacity))
}
.bg-yellow-200{
  --tw-bg-opacity: 1;
  background-color:rgb(254 240 138 / var(--tw-bg-opacity))
}
.bg-gray-200{
  --tw-bg-opacity: 1;
  background-color:rgb(229 231 235 / var(--tw-bg-opacity))
}
.bg-blue-900{
  --tw-bg-opacity: 1;
  background-color:rgb(30 58 138 / var(--tw-bg-opacity))
}
.p-4{
  padding:1rem
}
.p-3{
  padding:.75rem
}
.p-6{
  padding:1.5rem
}
.p-5{
  padding:1.25rem
}
.p-2{
  padding:.5rem
}
.py-6{
  padding-top:1.5rem;
  padding-bottom:1.5rem
}
.px-6{
  padding-left:1.5rem;
  padding-right:1.5rem
}
.px-4{
  padding-left:1rem;
  padding-right:1rem
}
.py-1{
  padding-top:.25rem;
  padding-bottom:.25rem
}
.py-2{
  padding-top:.5rem;
  padding-bottom:.5rem
}
.py-4{
  padding-top:1rem;
  padding-bottom:1rem
}
.pt-1{
  padding-top:.25rem
}
.pt-2{
  padding-top:.5rem
}
.pl-4{
  padding-left:1rem
}
.text-center{
  text-align:center
}
.align-middle{
  vertical-align:middle
}
.text-2xl{
  font-size:1.5rem;
  line-height:2rem
}
.text-xl{
  font-size:1.25rem;
  line-height:1.75rem
}
.text-lg{
  font-size:1.125rem;
  line-height:1.75rem
}
.text-sm{
  font-size:.875rem;
  line-height:1.25rem
}
.text-base{
  font-size:1rem;
  line-height:1.5rem
}
.text-xs{
  font-size:.75rem;
  line-height:1rem
}
.font-bold{
  font-weight:700
}
.font-black{
  font-weight:900
}
.font-extrabold{
  font-weight:800
}
.uppercase{
  text-transform:uppercase
}
.capitalize{
  text-transform:capitalize
}
.italic{
  font-style:italic
}
.leading-snug{
  line-height:1.375
}
.leading-normal{
  line-height:1.5
}
.tracking-wide{
  letter-spacing:.025em
}
.text-base-content{
  --tw-text-opacity: 1;
  color:hsl(var(--bc) / var(--tw-text-opacity))
}
.text-blue-500{
  --tw-text-opacity: 1;
  color:rgb(59 130 246 / var(--tw-text-opacity))
}
.text-white{
  --tw-text-opacity: 1;
  color:rgb(255 255 255 / var(--tw-text-opacity))
}
.text-red-500{
  --tw-text-opacity: 1;
  color:rgb(239 68 68 / var(--tw-text-opacity))
}
.text-black{
  --tw-text-opacity: 1;
  color:rgb(0 0 0 / var(--tw-text-opacity))
}
.text-green-500{
  --tw-text-opacity: 1;
  color:rgb(34 197 94 / var(--tw-text-opacity))
}
.text-orange-700{
  --tw-text-opacity: 1;
  color:rgb(194 65 12 / var(--tw-text-opacity))
}
.text-gray-300{
  --tw-text-opacity: 1;
  color:rgb(209 213 219 / var(--tw-text-opacity))
}
.text-red-600{
  --tw-text-opacity: 1;
  color:rgb(220 38 38 / var(--tw-text-opacity))
}
.text-blue-900{
  --tw-text-opacity: 1;
  color:rgb(30 58 138 / var(--tw-text-opacity))
}
.text-gray-400{
  --tw-text-opacity: 1;
  color:rgb(156 163 175 / var(--tw-text-opacity))
}
.text-gray-800{
  --tw-text-opacity: 1;
  color:rgb(31 41 55 / var(--tw-text-opacity))
}
.underline{
  -webkit-text-decoration-line:underline;
  text-decoration-line:underline
}
.shadow-md{
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}
.shadow-xl{
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / .1), 0 8px 10px -6px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}
.shadow-lg{
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}
.transition{
  transition-property:background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-backdrop-filter;
  transition-property:background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property:background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.15s
}
.transition-all{
  transition-property:all;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.15s
}
.hover\:bg-red-400:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(248 113 113 / var(--tw-bg-opacity))
}
.hover\:bg-red-500:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(239 68 68 / var(--tw-bg-opacity))
}
.hover\:bg-red-600:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(220 38 38 / var(--tw-bg-opacity))
}
.hover\:bg-red-700:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(185 28 28 / var(--tw-bg-opacity))
}
.hover\:bg-orange-400:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(251 146 60 / var(--tw-bg-opacity))
}
.hover\:bg-orange-500:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(249 115 22 / var(--tw-bg-opacity))
}
.hover\:bg-orange-600:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(234 88 12 / var(--tw-bg-opacity))
}
.hover\:bg-orange-700:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(194 65 12 / var(--tw-bg-opacity))
}
.hover\:bg-green-400:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(74 222 128 / var(--tw-bg-opacity))
}
.hover\:bg-green-500:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(34 197 94 / var(--tw-bg-opacity))
}
.hover\:bg-green-600:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(22 163 74 / var(--tw-bg-opacity))
}
.hover\:bg-green-700:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(21 128 61 / var(--tw-bg-opacity))
}
.hover\:bg-blue-400:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(96 165 250 / var(--tw-bg-opacity))
}
.hover\:bg-blue-500:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(59 130 246 / var(--tw-bg-opacity))
}
.hover\:bg-blue-600:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(37 99 235 / var(--tw-bg-opacity))
}
.hover\:bg-blue-700:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(29 78 216 / var(--tw-bg-opacity))
}
.hover\:bg-yellow-400:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(250 204 21 / var(--tw-bg-opacity))
}
@media (min-width: 768px){
  .md\:mt-4{
      margin-top:1rem
  }
  .md\:mt-8{
      margin-top:2rem
  }
  .md\:mb-4{
      margin-bottom:1rem
  }
  .md\:px-48{
      padding-left:12rem;
      padding-right:12rem
  }
  .md\:px-0{
      padding-left:0;
      padding-right:0
  }
  .md\:py-4{
      padding-top:1rem;
      padding-bottom:1rem
  }
  .md\:px-12{
      padding-left:3rem;
      padding-right:3rem
  }
  .md\:py-6{
      padding-top:1.5rem;
      padding-bottom:1.5rem
  }
  .md\:pt-4{
      padding-top:1rem
  }
  .md\:pl-16{
      padding-left:4rem
  }
  .md\:text-2xl{
      font-size:1.5rem;
      line-height:2rem
  }
  .md\:text-3xl{
      font-size:1.875rem;
      line-height:2.25rem
  }
  .md\:text-xl{
      font-size:1.25rem;
      line-height:1.75rem
  }
  .md\:text-base{
      font-size:1rem;
      line-height:1.5rem
  }
  .md\:text-4xl{
      font-size:2.25rem;
      line-height:2.5rem
  }
  .md\:leading-normal{
      line-height:1.5
  }
  .md\:leading-relaxed{
      line-height:1.625
  }
}

